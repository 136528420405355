/* eslint-disable @next/next/no-page-custom-font */
import Head from "next/head";
import { FC } from "react";

const AppHead: FC = () => {
  return (
    <Head>
      <title>Blank Theme</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
      />
    </Head>
  );
};

export default AppHead;
